import {createApp} from 'vue'
import App from './App.vue'


import './registerServiceWorker'
import 'bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/js/bootstrap.bundle'
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {faUserSecret} from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css'


import VueGtag from "vue-gtag";




library.add(faUserSecret)

const app = createApp(App)
app.use(VueGtag, {
    config: { id: "G-9L1SG9JVEE" }
})
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
