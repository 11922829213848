
<template>
<div>
  :آخرین بروزرسانی
  <br>
  {{lastUpdated}}
</div>
</template>
<script>

import axios from 'axios';
export default {

  data() {
    return {
      lastUpdated: '',
    };
  },
  mounted() {
    this.fetchLastUpdated();
  },
  methods: {
    fetchLastUpdated() {
      axios.get('last_updated.txt')
          .then(response => {
            this.lastUpdated = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
  },
}
</script>

<style scoped>

</style>