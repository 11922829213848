<script>


import axios from 'axios';
import goldenData from '../../public/golden.json'
import LastUpdate2 from "@/components/LastUpdate.vue";
import { digitsEnToFa } from 'persian-tools';


export default {

  components: {
    LastUpdate2
  },
  data() {
    return {
      details: [],
      goldenDetails: goldenData,


    }
  },
  mounted() {
    this.upDate()
    this.timer = setInterval(this.upDate, 1000)
  },
  methods: {
    formattedNumber(number) {
      return digitsEnToFa(number.toString());
    },
    upDate: function () {
      axios.get('golden.json', {
        headers: {
          'Cache-Control': 'no-cache'
        }
      }).then((response) => {
        this.details = response.data

      })
    },
  },


}

</script>


<template>
  <last-update2></last-update2>

  <div class="head-title golden mt-4"><h2>قیمت سکه و طلا</h2></div>
  <div class="container justify-content-center currency flex flex-wrap flex-row-reverse col-md-7 col-sm-12 mb-5 ">
    <div v-for="(det,index ) in details" :key="index"
         class="digital-box col-md-6 col-sm d-flex mt-4 justify-content-center">
      <div style="width: 240px" class="currency-box col-sm-12">
        <div class=" col-md-12 d-flex justify-content-around ">


            <img :src="det.image" class="col-md-6 col-sm-12 col-md-4  col-sm-4" :class=" 'image-'+ det.id "/>


          <div class="col-md-6 float-right pr-2 mt-2" >

            <div class="col-md-12 d-flex col-sm-12 justify-content-end mt-1 mb-1"><h6 class="font-1 pr-2" :class=" 'label-' + det.id " >{{ det.label}}</h6></div>

            <div class="col-md-12 col-sm-12 d-inline-flex justify-content-end mt-1">


              <div class="d-inline-flex mx-2 ">
                <i
                    :class="{ 'fas fa-arrow-down':det.upDown==='down' , 'fas fa-arrow-up':det.upDown==='up','fas fa-arrows-alt-h' :det.upDown ==='stable'}"></i>
              </div>

              <h6 class="font-1 pr-2" >{{ formattedNumber(det.price) }}</h6>
            </div>
          </div>


        </div>
      </div>


    </div>

  </div>

</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.price {
  margin-top: 20px;
}

h3 {
  color: #272727;
}

h6 {
  color: #272727;
}

i {
  color: #272727;
}

.last-icon {
  background-color: aqua;

}


.image-1 {
  width: 66px !important;
  height: 66px;
  margin-top: 4px;

}

.image-2 {
  width: 66px !important;
  height: 66px;
  margin-top: 4px;
}

.image-3 {
  width: 66px !important;
  height: 66px;
  margin-top: 4px;
}

.image-4 {
  width: 66px !important;
  height: 66px;
  margin-top: 4px;

}

.image-5 {
  width: 66px !important;
  height: 66px;

}

.image-6 {
  width: 57px !important;
  height: 60px;
  margin-top: 4px;
}

.label-6{
  padding-right: 5px !important;
}
.pr-2 {
  padding-right: 5px;
}

.golden {
  margin: 100px 0 20px;
}

.font-1 {
  font-size: 16px;
  font-weight: bold;
}

.currency-box {
  border: #5C9A6D 1px solid;
  height: 75px;
  width: 250px;
  border-radius: 30px;
  background-color: #5C9A6D;
  color: black;
  text-align: center;
  transition: all 500ms;
}

.currency-box:hover {

  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0px 10px 15px 0px #5C9A6D;
  transition: all 800ms;
}


.currency {
  display: flex;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 0 auto;
  }

  .currency-box {
    height: 75px;
    width: 95px;
  }

  .price {
    margin-top: 0;
  }
}

</style>
