<script>
export default {
  name: "BannerPwa",
  data() {
    return {
      deferredPrompt: null,

    };
  },
  mounted() {

    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });

  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    isIos() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod|macintosh/.test(userAgent);
    },

// Detects if device is on iOS
    isSafari() {
      return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') === -1 &&
          navigator.userAgent.indexOf('FxiOS') === -1;
    }


  }
}
</script>

<template>

  <div
      v-if="deferredPrompt"

      class="text-center m-2 banners-top"
  >
    <p class="banners-content">
      برنامه ی مارا رایگان دریافت کنید. فضای گوشی شما را اشغال نمی کند و به صورت آفلاین نیز کار می کند

    </p>

    <div class="banners-footer">
      <button class="btn-header mx-1" @click="dismiss">رد کردن</button>

      <button class="btn-header mx-1" @click="install"> نصب</button>
    </div>


  </div>
</template>

<style>
.btn-header {
  border: 1px solid #5C9A6D;
  background-color: #5C9A6D;
  color: #272727;
  border-radius: 20px;
  font-size: 13px;
  height: 35px;
  width: 70px;
  padding: 5px;
}

@media only screen and(min-width: 600px) {
  .banners-top, .banners-content, .banners-footer {
    display: block;
  }
}


</style>
