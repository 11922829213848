<template>
  <div class="digital-clock d-flex d-sm-flex  flex-sm-row flex-sm-wrap flex-wrap col-md-12 col-sm-12   mb-4">
    <div class=" col-md-6 col-sm-6 " style="margin: 0 auto !important;">

      {{ dateResult }}
    </div>
    <div class=" col-md-6 col-sm-6 " style="margin: 0 auto !important;">
      {{ timeResult }}
    </div>
    <div>

    </div>
  </div>
</template>

<script>

import {JalaliDateTime} from '@webilix/jalali-date-time';

export default {
  setup() {


    const jalali = JalaliDateTime();
    const dateResult = jalali.now({
      timezone: 'Asia/Tehran',
      locale: 'fa',
      format: 'Y/M/D',
    });
    const timeResult = jalali.now({
      timezone: 'Asia/Tehran',
      locale: 'fa',
      format: 'H:I:S',
    });
    return {
      dateResult,
      timeResult
    };
  }

}
</script>

<style>
.digital-clock{
  display:contents;
}
@media only screen and (max-width: 600px) {
.digital-clock{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
}
</style>
