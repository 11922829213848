<script>


import axios from 'axios';
import currenciesData from '../../public/currencies.json'
import lastUpdate from "@/components/LastUpdate.vue";
import { digitsEnToFa } from 'persian-tools';

export default {
  components:{
    lastUpdate
  },
  data() {
    return {
      results: [],
      currencies: currenciesData,
    }
  },
  computed: {},
  mounted() {
    this.upDate()
    this.timer = setInterval(this.upDate, 1000)
  },
  methods: {
    formattedNumber(number) {
      return digitsEnToFa(number.toString());
    },
    upDate: function () {
      axios.get('currencies.json',{

        headers:{
          'Cache-Control': 'no-cache'
        }
      }).then((response) => {
        this.results = response.data
      })
    },


  }
}

</script>

<template>
  <last-update></last-update>
  <div class="head-title mt-4"><h2>قیمت ارز</h2></div>
  <div class="container justify-content-center currency flex flex-wrap flex-row-reverse col-md-8 col-sm-12  mb-5">

    <div v-for="(res,index ) in results" :key="index"
         class="digital-box col-md-5 col-sm-5  d-flex  mt-4 justify-content-evenly">
      <div style="width: 240px" class="currency-box col-sm-12">
        <div class=" col-md-12 d-flex  justify-content-around mt-2">
          <div class="flag-img col-md-4   mt-1 px-2">

            <img :src="res.image" class="col-md-6 col-sm-12 "
                 alt="image"/>
          </div>

          <div class=" col-md-8 float-right pr-3">
            <div class="col-md-12 d-flex col-sm-12 justify-content-end mt-1 "><h6 class="font-1">{{ res.name }}</h6></div>
            <div class="price col-md-12 col-sm-12 d-inline-flex justify-content-end mt-2">
              <div class="d-inline-flex mx-2">

                <i :class="{ 'fas fa-arrow-down':res.upDown==='down' , 'fas fa-arrow-up':res.upDown==='up' ,'fas fa-arrows-alt-h' :res.upDown ==='stable'}  "></i>
<!--                <i v-else-if="res.upDown.includes('up')" class="fas fa-arrow-up"></i>-->
              </div>
              <h6 class="font-1">{{formattedNumber(res.price)  }}</h6>

            </div>
          </div>


        </div>

      </div>


    </div>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h3, h6, i {
  color: #272727;

}

.fa-arrows-alt-h{
  color:darkslategrey;
}
.fa-arrow-up{
  color: green;
}
.fa-arrow-down{
  color: red;
}

.flag-img img {
  width: 50px;
  height: 50px;
}

.digital-box {

}
.pr-3{
  padding-right: 18px;
}
.font-1 {
  font-size: 16px;
  font-weight: bold;
}

.currency-box {
  border: #5C9A6D 1px solid;
  height:85px;

  border-radius: 30px;
  background-color: #5C9A6D;
  color: black;
  text-align: center;
  transition: all 500ms;
}

.currency-box:hover {

  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0px 10px 15px 0px #5C9A6D;
  transition: all 800ms;
}

.price h6 {
  font-size: 15px;
}

.currency {
  display: flex;
}
.row > * {
padding-right: 0;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;

}

@media only screen and (max-width: 600px) {
  .currency-box {
    height: 75px;
  }

  .price {
    margin-top: 0;
  }
}

</style>
