<template>
  <digital-clock></digital-clock>
  <banner-pwa></banner-pwa>
  <hello-world></hello-world>

  <new-golden-item></new-golden-item>

</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import DigitalClock from "@/components/DigitalClock";
import NewGoldenItem from "@/components/NewGoldenItem";
import BannerPwa from "@/components/BannerPwa";




export default {
  head: {
    link: [
      {
        rel: "icon",
        href: require("./assets/logo.png")
      },
    ]
  },
  name: 'App',
  components: {
    DigitalClock,
    HelloWorld,
    NewGoldenItem,
    BannerPwa,


  },

  data() {
    return {
      deferredPrompt: null,

    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    this.$gtag.event('login', {method: 'Google'});
    this.$gtag.event('pwa', {
      'event_category': 'install',
      'event_label':'install button',
        'value': 1
          });
          },
          methods: {
          async dismiss() {
          this.deferredPrompt = null;
        },
          async install() {
          this.deferredPrompt.prompt();
        },


        }

          }


</script>

<style>
@font-face {
  font-family: iranyekan;
  src: url('/public/fonts/IRANYekanXVF.woff') format('woff');
}

#app {
  font-family: iranyekan, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #5C9A6D;

  margin-top: 20px;
}

</style>
